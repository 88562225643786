<!-- eslint-disable max-len -->
<template>
<div>
  <!-- <div class="pt-1 bg-primary position-fixed fixed-top shadow-sm" style="z-index: 999999;" :style="`width: ${load}%;`"></div> -->
  <!-- <nav class="navbar navbar-expand-lg navbar-sticky navbar-airy bg-fixed-white bg-transparent navbar-dark bg-hover-white navbar-hover-light navbar-fixed-light"> -->
  <nav @click="getRoute" class="navbar navbar-expand-lg navbar-sticky navbar-airy bg-fixed-white" :class="$route.path==='/'||$route.path==='/about'?'bg-transparent navbar-dark bg-hover-white navbar-hover-light navbar-fixed-light':'navbar-light bg-white'">
    <div class="container-fluid">
      <!-- Navbar Header  -->
      <a href="/" class="navbar-brand d-flex justify-content-between align-items-center">
        <svg viewBox="0 0 512 512" class="mr-3 ml-2 ml-lg-0 d-sm-none d-xl-inline-block" style="height: 33px; width: 33px; stroke-width: 1;"><path fill="currentColor" d="M351.64 160.79a206.88 206.88 0 0118 35.6C432.81 211.65 480 268.25 480 336c0 79.4-64.6 144-144 144s-144-64.6-144-144c0-66.45 45.53-121.62 106.86-138C312 219.67 320 244.83 320 272c0 55-31.37 102.36-76.83 126.59a112.52 112.52 0 0023.06 24.32C317.54 392.16 352 336.17 352 272c0-76.38-48.75-141.22-116.76-165.56L278 44.22 217.19 0h-82.38L74 44.22l42.76 62.22C48.75 130.78 0 195.62 0 272a176 176 0 00176 176 169.3 169.3 0 0023-1.64A175.93 175.93 0 00512 336c0-91.92-70.5-167.28-160.36-175.21zM118 51.78L145.22 32h61.56L234 51.78l-31.89 46.37A175.63 175.63 0 00176 96a175.63 175.63 0 00-26.11 2.15zM176 416c-79.4 0-144-64.6-144-144s64.6-144 144-144a143.52 143.52 0 01101.89 42.37C209.35 194.42 160 259.24 160 336a175.09 175.09 0 0019.21 79.68c-1.09.03-2.11.32-3.21.32z"/></svg>
        <!-- <svg viewBox="0 0 512 512" class="mr-3 ml-2 ml-lg-0 d-sm-none d-xl-inline-block" style="height: 33px; width: 33px; stroke-width: 1;"><path fill="currentColor" d="M371.94 163.7a222.3 222.3 0 0122.43 59C435.52 244.05 464 286.55 464 336a128 128 0 01-256 0c0-54.66 34.52-101.17 82.85-119.44A126.49 126.49 0 01304 272c0 37.37-16.38 70.73-42 94.15a80.3 80.3 0 0031 37 175.71 175.71 0 00-46.33-292.3L288 44.66 232.53 0H119.47L64 44.66l41.29 66.23A176 176 0 00176 448a164 164 0 0022.86-1.82A176 176 0 10371.94 163.7zM128 55.34l8.53-7.34h78.94l8.53 7.34-25.47 42.26a160 160 0 00-45.06 0zM48 272a128.14 128.14 0 01128-128c33 0 62.87 12.91 85.6 33.51-59.88 28-101.6 88-101.6 158.49a175.18 175.18 0 0012 63.6C103.33 397.45 48 341.22 48 272z"/></svg> -->
        <!-- <svg viewBox="0 0 512 512" class="mr-3 ml-2 ml-lg-0 d-sm-none d-xl-inline-block" style="height: 33px; width: 33px; stroke-width: 1;"><path fill="currentColor" d="M351.25 160.77A206.38 206.38 0 01379.9 233 112 112 0 11224 336c0-42.21 23.69-78.57 58.31-97.49 3.37 10.64 5.69 21.75 5.69 33.49a111.34 111.34 0 01-30.73 76.6A79.84 79.84 0 00293 403.23 175.36 175.36 0 00352 272c0-81.62-55.64-150.07-131-170l35-70-32-32h-96L96 32l35 70C55.64 121.93 0 190.38 0 272a176 176 0 00176 176 171.77 171.77 0 0022.94-1.71A175.93 175.93 0 00512 336c0-92-70.7-167.49-160.75-175.23zM64 272a112.12 112.12 0 01112-112c26.85 0 51.19 9.88 70.5 25.69C194.94 216.24 160 271.68 160 336a175.89 175.89 0 006.55 47C109.28 378.16 64 330.52 64 272z"/></svg> -->
        <div class="navbar-brand-svg pb-2 d-none d-sm-inline-block" viewBox="0 0 65 16" width="85" height="20" fill="none">
          <h1 class="navbar-brand-svg-text mainlogo">
            <em>iWed<span>hub</span></em>
          </h1>
        </div>
        <!-- <svg viewBox="0 0 512 512" class="ml-3" style="height: 33px; width: 33px; stroke-width: 1;"><path fill="currentColor" d="M351.64 160.79a206.88 206.88 0 0118 35.6C432.81 211.65 480 268.25 480 336c0 79.4-64.6 144-144 144s-144-64.6-144-144c0-66.45 45.53-121.62 106.86-138C312 219.67 320 244.83 320 272c0 55-31.37 102.36-76.83 126.59a112.52 112.52 0 0023.06 24.32C317.54 392.16 352 336.17 352 272c0-76.38-48.75-141.22-116.76-165.56L278 44.22 217.19 0h-82.38L74 44.22l42.76 62.22C48.75 130.78 0 195.62 0 272a176 176 0 00176 176 169.3 169.3 0 0023-1.64A175.93 175.93 0 00512 336c0-91.92-70.5-167.28-160.36-175.21zM118 51.78L145.22 32h61.56L234 51.78l-31.89 46.37A175.63 175.63 0 00176 96a175.63 175.63 0 00-26.11 2.15zM176 416c-79.4 0-144-64.6-144-144s64.6-144 144-144a143.52 143.52 0 01101.89 42.37C209.35 194.42 160 259.24 160 336a175.09 175.09 0 0019.21 79.68c-1.09.03-2.11.32-3.21.32z"/></svg> -->
      </a>
      <MNB @toggleMNB="showMNB=false" @logoutMNB="logout" :fullname="userFullName" :username="user.username" :profile_img_url="profile_img_url" :showMNB="showMNB" :loggedIn="loggedIn" :role="role"/>
      <!-- <router-link to="/" class="navbar-brand">
        <div class="navbar-brand-svg pb-2" viewBox="0 0 65 16" width="85" height="20" fill="none">
          <h1 class="navbar-brand-svg-text mainlogo"> <em>iWed<span>hub</span></em> </h1>
        </div>
      </router-link> -->
      <transition name="mnb">
        <div class="navbar-toggler navbar-toggler-right"  v-if="!showMNB" @click="showMNB=true">
          <svg class="svg-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" style="width: 33px; height: 33px; transform: rotate(180deg);"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16"/></svg>
        </div>
      </transition>
      <button type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"
      class="navbar-toggler navbar-toggler-right" v-if="false"><i class="fa fa-bars"></i></button>
      <!-- Navbar Collapse -->
      <div id="navbarCollapse" class="collapse navbar-collapse">
        <div class="navbar-collapse collapse show">
          <ul class="navbar-nav mx-auto pb-2 pt-3 py-lg-0 mr-lg-2">
            <!-- not loggedIn -->
            <li class="nav-item dropdown iwh-nav-item" v-if="!loggedIn">
              <a id="discoverDropdownMenuLink" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link ">Discover<i class="fa fa-angle-down"></i></a>
              <div aria-labelledby="discoverDropdownMenuLink" class="dropdown-menu dropdown-menu-left">
                <h6 class="dropdown-header font-weight-normal">Blog</h6>
                <a href="#" class="dropdown-item">Bride<span class="badge badge-warning ml-1">New</span></a>
                <a href="#" class="dropdown-item">Groom</a>
                <a href="#" class="dropdown-item">Entourage</a>
                <a href="#" class="dropdown-item">Top Vendors</a>
                <a href="#" class="dropdown-item">Top Stories</a>
                <a href="#" class="dropdown-item">Best Advice<span class="badge badge-warning ml-1">New</span></a>
                <div class="dropdown-divider"></div>
                <h6 class="dropdown-header font-weight-normal">Category</h6>
                <a href="#" class="dropdown-item">Engagement</a>
                <a href="#" class="dropdown-item">Wedding Preps</a>
                <a href="#" class="dropdown-item">Honeymoon Spots<span class="badge badge-warning ml-1">New</span> </a>
              </div>
            </li>
            <li class="nav-item" v-if="!loggedIn">
              <router-link to="/contact" class="nav-link">Contact</router-link>
            </li>
            <li class="nav-item" v-if="!loggedIn">
              <a href="/about" class="nav-link">About Us</a>
              <!-- <router-link to="/about" class="nav-link">About Us</router-link> -->

            </li>
            <!-- loggedIn -->
            <!-- <li class="nav-item dropdown" style="margin: 0; padding: 0;" v-if="loggedIn">
              <a id="docsDropdownMenuLink" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link">Discover<i class="far fa-lightbulb" data-toggle="tooltip" data-placement="top" title="" data-original-title="Discover"></i></a>
              <div aria-labelledby="docsDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
                <router-link to="/vendor-list" class="dropdown-item">Vendors<span class="badge badge-warning ml-1">New</span></router-link>
                <router-link to="/honeymoon-experiences" class="dropdown-item">Honeymoon Experiences</router-link>
              </div>
            </li> -->
            <!-- <li class="nav-item d-lg-none d-md-block" v-if="loggedIn">
              <a href="/couple#profile" class="nav-link">Profile</a>
            </li>
            <li class="nav-item d-lg-none d-md-block" v-if="loggedIn">
              <a href="/couple#wallet" class="nav-link">Wallet</a>
            </li>
            <div class="dropdown-divider d-lg-none d-md-block" v-if="loggedIn"></div>
            <li class="nav-item d-lg-none d-md-block" v-if="loggedIn && role.isC">
              <a href="/couple#launch" class="nav-link">Launch</a>
            </li>
            <li class="nav-item d-lg-none d-md-block" v-if="loggedIn && role.isC">
              <a href="/couple#budget" class="nav-link">Budget</a>
            </li>
            <li class="nav-item d-lg-none d-md-block" v-if="loggedIn && role.isC">
              <a href="/couple#guests" class="nav-link">Guests</a>
            </li>
            <div class="dropdown-divider d-lg-none d-md-block" v-if="loggedIn"></div>
            <li class="nav-item d-lg-none d-md-block" v-if="loggedIn && role.isC">
              <a href="/couple#budget" class="nav-link">Transactions</a>
            </li> -->
            <li class="nav-item d-lg-none d-md-block" v-if="loggedIn">
              <router-link :to="{ name: 'couple-profile' }" class="nav-link" v-if="role.isC">Couple Start</router-link>
              <router-link :to="{ name: 'guest' }" class="nav-link" v-if="role.isG">Guest Start</router-link>
              <router-link :to="{ name: 'vendor' }" class="nav-link" v-if="role.isV">Vendor Start</router-link>
            </li>
            <li class="nav-item d-lg-none d-md-block" v-if="loggedIn">
              <router-link to="/cart" class="nav-link" v-if="role.isC">Bookings</router-link>
            </li>
            <!-- <li class="nav-item dropdown" v-if="loggedIn">
              <a id="navbarDropdownMenuLink" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link">
                <span class="d-lg-none">Notifications</span>
                <i class="far fa-bell fa-lg d-none d-lg-inline-block" data-toggle="tooltip" data-placement="top" title="" data-original-title="Notifications"></i>
              </a>
              <ul aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
                <li><router-link to="#" class="dropdown-item">Messages</router-link></li>
              </ul>
            </li> -->
            <li class="nav-item dropdown" v-if="loggedIn">
              <a class="nav-link d-lg-none" href="/notifications">
                <span class="d-lg-none">Notifications</span>
              </a>
              <div class="nav-link d-none d-lg-block" style="letter-spacing: normal; text-transform: none;">
                <a class="navbar-icon-link dropdown-toggle" style="position: relative;" id="cartdetails" href="/cart" data-target="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg viewBox="0 0 448 512" class="svg-icon" style="height: 21px;"><path fill="currentColor" d="M224 480c-17.66 0-32-14.38-32-32.03h-32c0 35.31 28.72 64.03 64 64.03s64-28.72 64-64.03h-32c0 17.65-14.34 32.03-32 32.03zm209.38-145.19c-27.96-26.62-49.34-54.48-49.34-148.91 0-79.59-63.39-144.5-144.04-152.35V16c0-8.84-7.16-16-16-16s-16 7.16-16 16v17.56C127.35 41.41 63.96 106.31 63.96 185.9c0 94.42-21.39 122.29-49.35 148.91-13.97 13.3-18.38 33.41-11.25 51.23C10.64 404.24 28.16 416 48 416h352c19.84 0 37.36-11.77 44.64-29.97 7.13-17.82 2.71-37.92-11.26-51.22zM400 384H48c-14.23 0-21.34-16.47-11.32-26.01 34.86-33.19 59.28-70.34 59.28-172.08C95.96 118.53 153.23 64 224 64c70.76 0 128.04 54.52 128.04 121.9 0 101.35 24.21 138.7 59.28 172.08C421.38 367.57 414.17 384 400 384z"/></svg>
                  <div class="navbar-icon-link-badge" style="right: -6px;" v-if="Vendors.length !== 0">{{Vendors.length}}</div>
                </a>
                <div class="dropdown-menu dropdown-menu-right px-3 py-1" aria-labelledby="cartdetails">
                  <div class="navbar-cart-product-wrapper">
                    <!-- cart item-->
                    <div class="navbar-cart-product" v-for="(v,i) in Vendors" :key="i">
                      <div class="d-flex align-items-center">
                        <a href="/item">
                          <img class="profile-img" :src="v.vendor_img_url" alt="...">
                        </a>
                        <div class="w-100">
                          <div class="pl-3">
                            <a class="navbar-cart-product-link" href="/item">{{v.name}}</a><small class="d-block text-muted">{{v.category}}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- <li class="nav-item dropdown" v-if="loggedIn && role.isC">
              <a id="navbarDropdownMenuLink" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link">
                <span class="d-lg-none">Wishlist</span>
                <i class="far fa-heart fa-lg d-none d-lg-inline-block"  data-toggle="tooltip" data-placement="top" title="" data-original-title="Wishlist"></i></a>
              <ul aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-right">
                <li><router-link to="#" class="dropdown-item">Gifts & Experiences</router-link></li>
              </ul>
            </li> -->
          </ul>
          <!-- <div class="dropdown-divider d-lg-none d-md-block" v-if="loggedIn"></div> -->
          <div class="d-flex align-items-center justify-content-between justify-content-lg-end my-lg-0 mb-2">
            <!-- Search Button-->
            <div data-toggle="search" class="nav-item navbar-icon-link mr-lg-2" @click="openSearch">
              <svg class="svg-icon" viewBox="0 0 64 64"><path data-name="layer2" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" d="M39.049 39.049L56 56" stroke-linejoin="round" stroke-linecap="round"/><circle data-name="layer1" cx="27" cy="27" r="17" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/></svg>
            </div>
            <!-- User Not Logged - link to login page-->
            <div class="nav-item" v-if="!loggedIn">
              <!-- <a href="/login" class="navbar-icon-link" data-toggle="modal" data-target="#myModal"> -->
              <router-link to="/login" class="navbar-icon-link">
                <svg class="svg-icon" viewBox="0 0 64 64"><path data-name="layer2" d="M50.9 55.3a12.2 12.2 0 00-4.3-1.7c-8.1-1.5-8.1-4.1-8.1-5.7v-1a14.8 14.8 0 004.2-7.1c2.5 0 3-5.1 3-5.9s.1-3.8-2.4-3.8c5.4-14.6-8.9-20.5-19.6-13-4.4 0-4.8 6.5-3.1 13-2.5 0-2.4 3.1-2.4 3.8s.6 5.9 3 5.9a14.8 14.8 0 004.3 7.2v1c0 1.6 0 4.2-8.1 5.7a11.9 11.9 0 00-4.3 1.6" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/><circle data-name="layer1" cx="32" cy="32" r="30" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/></svg>
                <span class="text-sm ml-2 ml-lg-0 text-uppercase font-weight-bold d-none d-sm-inline d-lg-none">Log in</span>
              </router-link>
            </div>
            <!-- User Logged in -->
            <!-- <img :src="profile_img_url" alt="" class="profile-img d-lg-none d-md-block" v-if="loggedIn"> -->
            <ul class="navbar-nav d-none d-lg-inline-block" v-if="loggedIn">
              <li class="nav-item dropdown">
                <a  class="navbar-icon-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img v-if="user.profile_img_url.name" :src="profile_img_url" alt="" class="profile-img ">
                  <svg v-if="!user.profile_img_url.name" class="text-secondary" style="opacity: 0.5; height: 3rem;" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.15" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>
                  <!-- <svg v-if="!user.profile_img_url" width="32" height="32" style="opacity: 0.25;" fill="currentColor" class="text-dark" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                  </svg> -->
                </a>
                <div aria-labelledby="userdetails" class="dropdown-menu dropdown-menu-right" @click="topScroll">
                  <router-link :to="{ name: 'couple-profile' }" class="dropdown-item" v-if="role.isC">Couple Start</router-link>
                  <router-link :to="{ name: 'guest-profile' }" class="dropdown-item" v-if="role.isG">Guest Start</router-link>
                  <router-link :to="{ name: 'vendor-profile' }" class="dropdown-item" v-if="role.isV">Vendor Start</router-link>
                  <!-- <router-link :to="{ name: 'settings' }" class="dropdown-item">Settings
                    <svg viewBox="0 0 512 512" class="svg-icon ml-1" style="height: 16px; stroke-width: 8;"><path fill="currentColor" d="M482.696 299.276l-32.61-18.827a195.168 195.168 0 000-48.899l32.61-18.827c9.576-5.528 14.195-16.902 11.046-27.501-11.214-37.749-31.175-71.728-57.535-99.595-7.634-8.07-19.817-9.836-29.437-4.282l-32.562 18.798a194.125 194.125 0 00-42.339-24.48V38.049c0-11.13-7.652-20.804-18.484-23.367-37.644-8.909-77.118-8.91-114.77 0-10.831 2.563-18.484 12.236-18.484 23.367v37.614a194.101 194.101 0 00-42.339 24.48L105.23 81.345c-9.621-5.554-21.804-3.788-29.437 4.282-26.36 27.867-46.321 61.847-57.535 99.595-3.149 10.599 1.47 21.972 11.046 27.501l32.61 18.827a195.168 195.168 0 000 48.899l-32.61 18.827c-9.576 5.528-14.195 16.902-11.046 27.501 11.214 37.748 31.175 71.728 57.535 99.595 7.634 8.07 19.817 9.836 29.437 4.283l32.562-18.798a194.08 194.08 0 0042.339 24.479v37.614c0 11.13 7.652 20.804 18.484 23.367 37.645 8.909 77.118 8.91 114.77 0 10.831-2.563 18.484-12.236 18.484-23.367v-37.614a194.138 194.138 0 0042.339-24.479l32.562 18.798c9.62 5.554 21.803 3.788 29.437-4.283 26.36-27.867 46.321-61.847 57.535-99.595 3.149-10.599-1.47-21.972-11.046-27.501zm-65.479 100.461l-46.309-26.74c-26.988 23.071-36.559 28.876-71.039 41.059v53.479a217.145 217.145 0 01-87.738 0v-53.479c-33.621-11.879-43.355-17.395-71.039-41.059l-46.309 26.74c-19.71-22.09-34.689-47.989-43.929-75.958l46.329-26.74c-6.535-35.417-6.538-46.644 0-82.079l-46.329-26.74c9.24-27.969 24.22-53.869 43.929-75.969l46.309 26.76c27.377-23.434 37.063-29.065 71.039-41.069V44.464a216.79 216.79 0 0187.738 0v53.479c33.978 12.005 43.665 17.637 71.039 41.069l46.309-26.76c19.709 22.099 34.689 47.999 43.929 75.969l-46.329 26.74c6.536 35.426 6.538 46.644 0 82.079l46.329 26.74c-9.24 27.968-24.219 53.868-43.929 75.957zM256 160c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"/></svg>
                  </router-link> -->
                  <div class="dropdown-divider my-0"></div>
                  <a href="#" class="dropdown-item" v-on:click="logout">Log out
                    <svg class="svg-icon ml-1" viewBox="0 0 512 512" style="height: 16px; stroke-width: 8;"><path fill="currentColor" d="M160 217.1c0-8.8 7.2-16 16-16h144v-93.9c0-7.1 8.6-10.7 13.6-5.7l141.6 143.1c6.3 6.3 6.3 16.4 0 22.7L333.6 410.4c-5 5-13.6 1.5-13.6-5.7v-93.9H176c-8.8 0-16-7.2-16-16v-77.7m-32 0v77.7c0 26.5 21.5 48 48 48h112v61.9c0 35.5 43 53.5 68.2 28.3l141.7-143c18.8-18.8 18.8-49.2 0-68L356.2 78.9c-25.1-25.1-68.2-7.3-68.2 28.3v61.9H176c-26.5 0-48 21.6-48 48zM0 112v288c0 26.5 21.5 48 48 48h132c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H48c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h132c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H48C21.5 64 0 85.5 0 112z"/></svg>
                    <!-- <i class="fas fa-sign-out-alt"></i> -->
                  </a>
                </div>
              </li>
            </ul>
            <!-- Logout button sm -->
            <div class="nav-item d-lg-none d-md-block py-4" v-if="loggedIn">
              <a href="#" class="navbar-icon-link" v-on:click="logout">
                <span class="text-uppercase text-sm font-weight-bold d-none d-sm-block mr-2">Log Out</span>
                <svg class="svg-icon d-sm-none text-dark" viewBox="0 0 512 512"><path fill="currentColor" d="M160 217.1c0-8.8 7.2-16 16-16h144v-93.9c0-7.1 8.6-10.7 13.6-5.7l141.6 143.1c6.3 6.3 6.3 16.4 0 22.7L333.6 410.4c-5 5-13.6 1.5-13.6-5.7v-93.9H176c-8.8 0-16-7.2-16-16v-77.7m-32 0v77.7c0 26.5 21.5 48 48 48h112v61.9c0 35.5 43 53.5 68.2 28.3l141.7-143c18.8-18.8 18.8-49.2 0-68L356.2 78.9c-25.1-25.1-68.2-7.3-68.2 28.3v61.9H176c-26.5 0-48 21.6-48 48zM0 112v288c0 26.5 21.5 48 48 48h132c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H48c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h132c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H48C21.5 64 0 85.5 0 112z"/></svg>
              </a>
            </div>
            <div class="nav-item dropdown d-none d-lg-block" v-if="loggedIn&&role.isC">
              <div class="d-none d-lg-block">
                <a class="navbar-icon-link dropdown-toggle" id="cartdetails" href="/cart" data-target="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <!-- <svg class="svg-icon" viewBox="0 0 64 64"><path data-name="layer1" fill="none" stroke="var(--layer2, #202020)" stroke-miterlimit="10" stroke-width="2" d="M52 37h10v22H52zm0 18.2c-2 0-14.9 5.8-22 5.8-4.2 0-7.9-2.2-16-6-3.8-1.8-12-6.6-12-10s10.9 1.1 20.1 4" stroke-linejoin="round" stroke-linecap="round"/><circle data-name="layer2" cx="34.1" cy="11" r="8" fill="none" stroke="var(--layer2, #202020)" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"/><circle data-name="layer2" cx="20" cy="29" r="8" fill="none" stroke="var(--layer2, #202020)" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"/><path data-name="layer1" d="M36.1 53h-10a4 4 0 01-4-4 4 4 0 014-4h4c12 0 8-6 22-6" fill="none" stroke="var(--layer2, #202020)" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"/></svg> -->
                  <svg class="svg-icon" viewBox="0 0 64 64"><path data-name="layer2" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" d="M2 6h10l10 40h32l8-24H16" stroke-linejoin="round" stroke-linecap="round"/><circle data-name="layer1" cx="23" cy="54" r="4" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/><circle data-name="layer1" cx="49" cy="54" r="4" fill="none" stroke="var(--layer1, #202020)" stroke-miterlimit="10" stroke-linejoin="round" stroke-linecap="round"/></svg>
                  <!-- <svg viewBox="0 0 496 512" class="svg-icon" style="width: 21px; height: 21px; stroke-width: 1;"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm90.2-146.2C315.8 352.6 282.9 368 248 368s-67.8-15.4-90.2-42.2c-5.7-6.8-15.8-7.7-22.5-2-6.8 5.7-7.7 15.7-2 22.5C161.7 380.4 203.6 400 248 400s86.3-19.6 114.8-53.8c5.7-6.8 4.8-16.9-2-22.5-6.8-5.6-16.9-4.7-22.6 2.1zM168 240c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"/></svg> -->
                  <div class="navbar-icon-link-badge">{{Vendors.length}}</div>
                </a>
                <div class="dropdown-menu dropdown-menu-right p-4" aria-labelledby="cartdetails">
                  <div class="navbar-cart-product-wrapper">
                    <!-- cart item-->
                    <div class="navbar-cart-product" v-for="(v,i) in Vendors" :key="i">
                      <div class="d-flex align-items-center">
                        <a href="/item">
                          <img class="img-fluid navbar-cart-product-image" :src="v.vendor_img_url" alt="...">
                        </a>
                        <div class="w-100"><a class="close text-sm mr-2" href="#"><i class="fa fa-times"></i></a>
                          <div class="pl-3">
                            <a class="navbar-cart-product-link" href="/item">{{v.name}}</a><small class="d-block text-muted">{{v.category}}</small>
                            <strong class="d-block text-sm">${{v.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- total price-->
                  <div class="navbar-cart-total"><span class="text-uppercase text-muted">Total</span><strong class="text-uppercase">${{totalPrice}}</strong></div>
                  <!-- buttons-->
                  <div class="d-flex justify-content-between">
                    <a class="btn btn-link text-dark mr-3" href="/cart">View Cart <i class="fa-arrow-right fa"></i></a>
                    <a class="btn btn-outline-dark" href="/checkout">Checkout</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import MobNav from 'vue-nav-ui';
import { ref } from 'vue';
// import axios from 'axios';
// import * as firebase from 'firebase/app';
// import 'firebase/auth';
import MNB from './MobileNavBar.vue';

export default {
  name: 'NavBar',
  components: { MNB },
  setup() {
    /* eslint-disable max-len */
    /* eslint-disable quotes */
    /* FOR YOUR NAVIGATION LINKING NAMES AND PATHS */
    const navLinks = ref([
      {
        name: "Home",
        path: "/",
      },
      {
        name: "About",
        path: "/about",
      },
      {
        name: "Contact",
        path: "/contact",
      },
      {
        name: "FAQs",
        path: "/faqs",
      },
    ]);

    /* FOR CONFIGURING THE STYLING OF YOUR NAVIGATION */
    const navConfig = ref({
      whitespace: true, /* GIVES PADDING TO YOUR NAV, IF SET TO FALSE, REMOVES PADDING */
      navBg: "#FAFAFA", /* BACKGROUND COLOR OF YOUR NAV  */
      navBorderRadius: "30px", /* BORDER RADIUS OF YOUR NAV */
      linkFont: "HK Grotesk", /* FONT FAMILY OF YOUR NAV */
      linkColor: "black", /* FONT COLOR OF YOUR NAV */
      responsivePosition: "bottom", /* FOR CHANGING THE POSITION OF YOUR NAV WHEN RESPONSIVE. BOTTOM or TOP | The only two options */
    });

    /* FOR NAV BUTTON CONFIGURATION */
    const btnConfig = ref({
      btnLink: true, /* FOR INITIALIZING NAV BUTTON USAGE, IF SET TO FALSE, REMOVES THE NAV BUTTON  */
      btnUrl: "https://dhaniel.disha.page", /* LINK URL OF YOUR NAV BUTTON */
      btnText: "Download app", /* NAV BUTTON TEXT */
      btnBg: "#40269E", /* BACKGROUND COLOR OF YOUR NAV BUTTON */
      btnTextColor: "white", /* FONT COLOR OF YOUR NAV BUTTON */
      btnBorderWidth: "0", /* BORDER WIDTH OF YOUR NAV BUTTON */
      btnBorderColor: "black", /* BORDER COLOR OF YOUR NAV BUTTON */
      btnBorderRadius: "20px", /* BORDER RADIUS OF YOUR NAV BUTTON */
    });

    return { navLinks, btnConfig, navConfig };
  },
  data: () => ({
    transparent: false,
    showMNB: false,
    currentUser: {},
    load: 0,
  }),
  created() {
    this.$store.dispatch('setUserRole');
    // console.log(this.role, 'navbar');
    // if (firebase.auth().currentUser) {
    //   this.loggedIn = true;
    //   this.currentUser = firebase.auth().currentUser;
    // }
    this.loading();
  },
  updated() {
    // this.$store.dispatch('setUserRole');
  },
  methods: {
    logout() {
      // firebase.auth().signOut().then(() => {
      //   this.loggedIn = false;
      //   this.currentUser = {};
      //   this.$router.push('/login');
      // });
      localStorage.removeItem('_ikn');
      localStorage.removeItem('_ud');
      localStorage.removeItem('_rlc');
      localStorage.removeItem('_rlg');
      localStorage.removeItem('_rlv');
      localStorage.removeItem('_acc');
      window.location = '/logout';
    },
    getRoute() {
      // console.log(this.$route.path);
      // console.log(this.$route.name);
      // this.$store.dispatch('smoothScroll');
      // window.scroll({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth',
      // });
      if (this.$route.path === '/' || this.$route.path === '/about') {
        this.transparent = true;
      } else {
        this.transparent = false;
      }
    },
    openSearch() {
      this.$store.commit('setSearch', true);
    },
    loading() {
      if (this.load < 100) {
        setTimeout(() => {
          this.load += 1;
          this.loading();
        }, 10);
      } else if (this.load === 100) {
        this.load = 0;
      }
    },
    topScroll() {
      this.$store.dispatch('smoothScroll');
    },
  },
  computed: {
    ...mapState(['role', 'Vendors', 'showSearchArea']),
    ...mapGetters(['token', 'profile_img_url', 'user']),
    userFullName() {
      if (this.role.isC) return `${this.user.groom.full_name.split(' ')[0]} & ${this.user.bride.full_name.split(' ')[0]}`;
      // if (this.role.isC) return `${this.user.groom_name} & ${this.user.bride_name}`;
      if (this.role.isG) return this.user.full_name;
      if (this.role.isV) return this.user.business_name;
      return '';
    },
    loggedIn() {
      // const tkn = localStorage.getItem('_ikn');
      if (this.token) return true;
      return false;
    },
    totalPrice() {
      let tp = 0;
      this.Vendors.forEach((v) => {
        tp += v.price;
      });
      return tp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>

<style scoped>
.profile-img {
  background: #fff;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
}
@media (max-width: 991px) {
  .iwh-nav-item {
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .iwh-nav-item {
    /* margin-right: 4.5rem; */
    margin-right: 0;
  }
}
.iwh-user-nav {
  flex-grow: 0 !important;
}
.mnb-enter-active, .mnb-leave-active {
  transition: all .05s ease;
}
.mnb-enter-from, .mnb-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}
</style>
